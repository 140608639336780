import React from 'react';

const techSnippets = [
    // ML/AI
    'coffeeModel.fit(beans_train, roast_train)',
    'predicted_taste = model.predict(new_beans_test)',
    'coffee_gpu.grinder.is_available()',
    'tf.keras.layers.Blend(128, activation="steam")',
    'taste_accuracy_score(expected_flavor, predicted_flavor)',

    // Data Science
    'df.groupby("bean_origin")',
    'plt.scatter(brewing_time, flavor_score)',
    'np.mean(coffee_ratings)',
    'sns.heatmap(flavor_correlation_matrix)',

    // Backend
    'async getBaristaById(id: string)',
    'brewingSession.start(waterTemp: number)',
    'coffeeCache.invalidate()',
    'espresso.pull(shots: number = 2)',

    // Frontend
    '<BaristaButton onClick={pullShot} />',
    'const [beanType, setBeanType] = useState("arabica")',
    'CoffeeContext.Provider value={brewSettings}',
    'style={{ color: darkRoast }}',

    // Database
    'SELECT * FROM bean_inventory',
    'INNER JOIN brew_orders',
    'await coffee.saveToInventory()',
    'db.beanCollection.find({ roastLevel: "medium" })',

    // DevOps
    'docker-compose up coffee-service',
    'kubectl get pods -n coffee-shop',
    'git commit -m "☕ feat: add new espresso endpoint"',
    'npm install barista-utils',

    // System
    'coffee.service.start()',
    'systemctl status coffee-machine',
    'beanGrinder.stream.pipe(portaFilter)',
    '/dev/espresso-machine'
];

interface CodeLineProps {
    direction: 'left' | 'right';
    yPosition: number;
    delay: number;
}

const CodeLine = ({ direction, yPosition, delay }: CodeLineProps) => (
    <div
        className="absolute text-brown-400/40 text-base md:text-lg font-mono whitespace-nowrap font-medium"
        style={{
            top: `${yPosition}%`,
            animation: `flow-${direction} ${30 + Math.random() * 10}s linear ${delay}s infinite`,
            transform: direction === 'left' ? 'translateX(100vw)' : 'translateX(-100%)'
        }}
    >
        {techSnippets[Math.floor(Math.random() * techSnippets.length)]}
    </div>
);

const GeometricBackground: React.FC = () => {
    // Create an array of line positions with alternating directions
    const codeLines: CodeLineProps[] = [...Array(20)].map((_, i) => ({
        direction: i % 2 === 0 ? 'left' : 'right' as const,
        yPosition: 5 + (i * 5), // Space lines evenly
        delay: Math.random() * 5
    }));

    return (
        <div className="absolute inset-0 overflow-hidden bg-brown-50">
            {/* Grid pattern overlay */}
            <div
                className="absolute inset-0 opacity-[0.07]"
                style={{
                    backgroundImage: `
                        linear-gradient(to right, #8B4513 1px, transparent 1px),
                        linear-gradient(to bottom, #8B4513 1px, transparent 1px)
                    `,
                    backgroundSize: '20px 20px'
                }}
            />

            {/* Flowing code lines */}
            <div className="absolute inset-0">
                {codeLines.map((line, i) => (
                    <CodeLine key={i} {...line} />
                ))}
            </div>

            {/* Add required keyframes */}
            <style>
                {`
                    @keyframes flow-left {
                        from { transform: translateX(100vw); }
                        to { transform: translateX(-100%); }
                    }
                    @keyframes flow-right {
                        from { transform: translateX(-100%); }
                        to { transform: translateX(100vw); }
                    }
                `}
            </style>
        </div>
    );
};

export default GeometricBackground;
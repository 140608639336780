import React, { useMemo } from 'react';
import { ProjectArea } from '../../types';
import { projects } from '../../data';

interface AreaFilterProps {
    selectedArea: ProjectArea | null;
    onAreaClick: (area: ProjectArea) => void;
    hasFilters: boolean;
    onClearFilters: () => void;
}

const AreaFilter: React.FC<AreaFilterProps> = ({
    selectedArea,
    onAreaClick,
    hasFilters,
    onClearFilters
}) => {
    // Get areas that have at least one project
    const availableAreas = useMemo(() => {
        const areas = new Set<ProjectArea>();
        projects.forEach(project => {
            project.areas.forEach(area => areas.add(area));
        });
        return Array.from(areas).sort();
    }, []);

    return (
        <div className="flex flex-wrap gap-2">
            {availableAreas.map(area => (
                <button
                    key={area}
                    onClick={() => onAreaClick(area)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${selectedArea === area
                            ? 'bg-brown-600 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                >
                    {area === 'personal' ? 'Personal Projects' : area.charAt(0).toUpperCase() + area.slice(1)}
                </button>
            ))}
        </div>
    );
};

export default AreaFilter; 
import React, { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

interface CoffeeLocation {
    name: string;
    coordinates: [number, number];
    country: string;
    notes?: string;
}

const coffeeLocations: CoffeeLocation[] = [
    // Pakistan
    { name: "Lahore", coordinates: [31.5204, 74.3587], country: "Pakistan" },
    { name: "Islamabad", coordinates: [33.6844, 73.0479], country: "Pakistan" },
    { name: "Karachi", coordinates: [24.8607, 67.0011], country: "Pakistan" },

    // Turkey & Georgia
    { name: "Istanbul", coordinates: [41.0082, 28.9784], country: "Turkey" },
    { name: "Tbilisi", coordinates: [41.7167, 44.7833], country: "Georgia" },

    // France
    { name: "Paris", coordinates: [48.8566, 2.3522], country: "France" },
    { name: "Lyon", coordinates: [45.7640, 4.8357], country: "France" },
    { name: "Bordeaux", coordinates: [44.8378, -0.5792], country: "France" },
    { name: "Nice", coordinates: [43.7102, 7.2620], country: "France" },
    { name: "Strasbourg", coordinates: [48.5734, 7.7521], country: "France" },
    { name: "Marseille", coordinates: [43.2965, 5.3698], country: "France" },

    // Italy
    { name: "Rome", coordinates: [41.9028, 12.4964], country: "Italy" },
    { name: "Florence", coordinates: [43.7696, 11.2558], country: "Italy" },

    // European Capitals
    { name: "London", coordinates: [51.5074, -0.1276], country: "UK" },
    { name: "Amsterdam", coordinates: [52.3676, 4.9041], country: "Netherlands" },
    { name: "Berlin", coordinates: [52.5200, 13.4050], country: "Germany" },
    { name: "Vienna", coordinates: [48.2082, 16.3738], country: "Austria" },
    { name: "Madrid", coordinates: [40.4168, -3.7038], country: "Spain" },
    { name: "Stockholm", coordinates: [59.3293, 18.0686], country: "Sweden" },
    { name: "Copenhagen", coordinates: [55.6761, 12.5683], country: "Denmark" },
    { name: "Prague", coordinates: [50.0755, 14.4378], country: "Czech Republic" },
    { name: "Budapest", coordinates: [47.4979, 19.0402], country: "Hungary" },
    { name: "Warsaw", coordinates: [52.2297, 21.0122], country: "Poland" },

    // North America
    { name: "Vancouver", coordinates: [49.2827, -123.1207], country: "Canada" },
    { name: "Seattle", coordinates: [47.6062, -122.3321], country: "USA" },
    { name: "San Francisco", coordinates: [37.7749, -122.4194], country: "USA" },
    { name: "Los Angeles", coordinates: [34.0522, -118.2437], country: "USA" },
    { name: "Chicago", coordinates: [41.8781, -87.6298], country: "USA" },
    { name: "New York", coordinates: [40.7128, -74.0059], country: "USA" },
    { name: "Toronto", coordinates: [43.6532, -79.3832], country: "Canada" },
    { name: "Montreal", coordinates: [45.5017, -73.5673], country: "Canada" },

    // Middle East & Asia
    { name: "Dubai", coordinates: [25.2048, 55.2708], country: "UAE" },
    { name: "Abu Dhabi", coordinates: [24.4539, 54.3773], country: "UAE" },
    { name: "Doha", coordinates: [25.2867, 51.5310], country: "Qatar" },
    { name: "Bangkok", coordinates: [13.7563, 100.5018], country: "Thailand" },
    { name: "Taipei", coordinates: [25.0330, 121.5654], country: "Taiwan" },
];

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const RegionFilter = ({ activeRegion, setActiveRegion, isExpanded, setIsExpanded }: {
    activeRegion: string;
    setActiveRegion: (region: string) => void;
    isExpanded: boolean;
    setIsExpanded: (expanded: boolean) => void;
}) => {
    const regions = {
        all: 'All Locations',
        europe: 'Europe',
        asia: 'Asia & Middle East',
        'north-america': 'North America'
    };

    return (
        <div className="relative z-[500]">
            <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="md:hidden w-full px-4 py-2 bg-brown-600 text-white rounded-lg flex items-center justify-between"
            >
                <span>Filter by Region</span>
                <svg
                    className={`w-5 h-5 transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </button>

            <div className={`
                md:flex md:flex-row gap-2 overflow-x-auto pb-2
                ${isExpanded ? 'flex' : 'hidden'}
                flex-col absolute md:relative w-full bg-white md:bg-transparent p-2 md:p-0 rounded-lg shadow-lg md:shadow-none mt-2 md:mt-0
            `}>
                {Object.entries(regions).map(([key, label]) => (
                    <button
                        key={key}
                        onClick={() => {
                            setActiveRegion(key);
                            setIsExpanded(false);
                        }}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition-colors whitespace-nowrap
                            ${activeRegion === key
                                ? 'bg-brown-600 text-white'
                                : 'bg-brown-50 text-brown-600 hover:bg-brown-100'
                            }`}
                    >
                        {label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default function CoffeeMap({ isOpen, onClose }: Props) {
    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useRef<L.Map | null>(null);
    const markersRef = useRef<L.Marker[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<CoffeeLocation | null>(null);
    const [activeRegion, setActiveRegion] = useState<string>('all');
    const [isFilterExpanded, setIsFilterExpanded] = useState(false);

    const getRegionForCountry = (country: string) => {
        const regionMap: { [key: string]: string } = {
            USA: 'north-america',
            Canada: 'north-america',
            UK: 'europe',
            France: 'europe',
            Italy: 'europe',
            Germany: 'europe',
            Spain: 'europe',
            Netherlands: 'europe',
            Sweden: 'europe',
            Denmark: 'europe',
            'Czech Republic': 'europe',
            Hungary: 'europe',
            Poland: 'europe',
            Austria: 'europe',
            UAE: 'asia',
            Qatar: 'asia',
            Thailand: 'asia',
            Taiwan: 'asia',
            Pakistan: 'asia',
            Turkey: 'asia',
            Georgia: 'asia'
        };
        return regionMap[country] || 'other';
    };

    const filteredLocations = coffeeLocations.filter(
        location => activeRegion === 'all' || getRegionForCountry(location.country) === activeRegion
    );

    const fitMapToBounds = (locations: CoffeeLocation[]) => {
        if (!map.current || locations.length === 0) return;

        const bounds = L.latLngBounds(locations.map(loc => loc.coordinates));
        map.current.flyToBounds(bounds, {
            padding: [50, 50],
            duration: 1.5,
            maxZoom: 5
        });
    };

    // Initialize map
    useEffect(() => {
        if (!isOpen || !mapContainer.current || map.current) return;

        map.current = L.map(mapContainer.current, {
            center: [30, 0],
            zoom: 2,
            zoomControl: false,
            scrollWheelZoom: true
        });

        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
            attribution: '©OpenStreetMap, ©CartoDB',
            maxZoom: 19
        }).addTo(map.current);

        L.control.zoom({
            position: 'topright'
        }).addTo(map.current);

        // Force a resize after a short delay
        setTimeout(() => {
            map.current?.invalidateSize();
            updateMarkers();
        }, 100);

        return () => {
            if (map.current) {
                clearMarkers();
                map.current.remove();
                map.current = null;
            }
        };
    }, [isOpen]);

    // Function to clear markers
    const clearMarkers = () => {
        markersRef.current.forEach(marker => {
            marker.remove();
        });
        markersRef.current = [];
    };

    // Function to update markers
    const updateMarkers = () => {
        if (!map.current) return;

        clearMarkers();

        const coffeeIcon = L.divIcon({
            html: `<div class="coffee-marker-inner">☕</div>`,
            className: 'coffee-marker',
            iconSize: [40, 40],
            iconAnchor: [20, 20]
        });

        filteredLocations.forEach((location) => {
            const marker = L.marker(location.coordinates, { icon: coffeeIcon })
                .addTo(map.current!)
                .on('click', () => {
                    setSelectedLocation(location);
                    map.current?.flyTo(location.coordinates, 8, {
                        duration: 1.5
                    });
                });
            markersRef.current.push(marker);
        });

        fitMapToBounds(filteredLocations);
    };

    // Update markers when filtered locations change
    useEffect(() => {
        if (map.current) {
            updateMarkers();
        }
    }, [filteredLocations]);

    // Clear selected location when region changes
    useEffect(() => {
        setSelectedLocation(null);
    }, [activeRegion]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-0 sm:p-4"
                    onClick={onClose}
                >
                    <motion.div
                        initial={{ scale: 0.9 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.9 }}
                        className="bg-white w-full h-full sm:rounded-2xl sm:p-8 sm:max-w-7xl sm:h-[90vh] flex flex-col"
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="flex flex-col h-full p-4 sm:p-0">
                            {/* Header */}
                            <div className="flex justify-between items-center mb-4 sm:mb-6">
                                <div>
                                    <h2 className="text-2xl sm:text-3xl font-display font-bold text-brown-800">
                                        Coffee Adventures Map
                                    </h2>
                                    <p className="text-brown-600 mt-1 text-sm sm:text-base">
                                        I've had coffee in these cities around the globe
                                    </p>
                                </div>
                                <button
                                    onClick={onClose}
                                    className="p-2 hover:bg-brown-50 rounded-full transition-colors"
                                >
                                    <svg className="w-6 h-6 text-brown-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>

                            {/* Region Filter */}
                            <RegionFilter
                                activeRegion={activeRegion}
                                setActiveRegion={setActiveRegion}
                                isExpanded={isFilterExpanded}
                                setIsExpanded={setIsFilterExpanded}
                            />

                            {/* Map Container */}
                            <div className="relative flex-1 rounded-xl overflow-hidden bg-brown-50 min-h-[400px] mt-4">
                                <div ref={mapContainer} className="absolute inset-0 z-0" />

                                {/* Location Info Overlay */}
                                <AnimatePresence>
                                    {selectedLocation && (
                                        <motion.div
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: 20 }}
                                            className="absolute bottom-4 left-4 right-4 sm:right-auto bg-white p-4 sm:p-6 rounded-xl shadow-lg sm:max-w-sm z-[400]"
                                        >
                                            <div className="flex items-start justify-between">
                                                <div>
                                                    <h3 className="font-display font-bold text-xl text-brown-800">
                                                        {selectedLocation.name}
                                                    </h3>
                                                    <p className="text-brown-600 text-sm mt-1">{selectedLocation.country}</p>
                                                </div>
                                                <button
                                                    onClick={() => setSelectedLocation(null)}
                                                    className="p-1 hover:bg-brown-50 rounded-full transition-colors"
                                                >
                                                    <svg className="w-5 h-5 text-brown-400 hover:text-brown-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                            {selectedLocation.notes && (
                                                <p className="text-brown-500 text-sm mt-3 leading-relaxed">
                                                    {selectedLocation.notes}
                                                </p>
                                            )}
                                        </motion.div>
                                    )}
                                </AnimatePresence>

                                {/* Stats Overlay */}
                                <div className="absolute top-4 right-4 bg-white p-3 sm:p-4 rounded-lg shadow-lg z-[400] text-xs sm:text-sm">
                                    <p className="text-brown-600">
                                        Showing {filteredLocations.length} locations
                                    </p>
                                </div>
                            </div>

                            {/* Footer */}
                            <div className="mt-4 text-center text-xs sm:text-sm text-brown-500">
                                <p>Click markers to view details • Use mouse wheel to zoom • Drag to pan</p>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
} 
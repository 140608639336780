import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { personalInfo } from '../data';

const Navigation: React.FC = () => {
    const [scrolled, setScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 20);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const menuItems = [
        { name: 'About', href: '#about' },
        { name: 'Projects', href: '#projects' },
        { name: 'Work History', href: '#work-history' },
        { name: 'Coffee', href: '#coffee' },
        { name: 'Contact', href: '#contact' }
    ];

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <>
            <motion.nav
                className={`fixed w-full z-50 transition-all duration-300 ${scrolled || isMenuOpen ? 'bg-white shadow-lg' : 'bg-transparent'
                    }`}
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className="container mx-auto px-6">
                    <div className="flex items-center justify-between h-16 md:h-20">
                        <motion.div
                            className={`text-xl font-bold ${scrolled || isMenuOpen ? 'text-brown-800' : 'text-brown-700'
                                }`}
                        >
                            <span className="text-2xl mr-2">☕</span>
                            {personalInfo.name}
                        </motion.div>

                        <div className="hidden md:flex items-center space-x-1">
                            {menuItems.map((item) => (
                                <motion.a
                                    key={item.name}
                                    href={item.href}
                                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                                        ${scrolled
                                            ? 'text-brown-600 hover:bg-brown-50 hover:text-brown-800'
                                            : 'text-brown-700 hover:bg-white/10 hover:text-brown-900'
                                        }`}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {item.name}
                                </motion.a>
                            ))}
                        </div>

                        {/* Mobile menu button */}
                        <motion.button
                            className="md:hidden p-2 rounded-lg hover:bg-brown-50"
                            whileTap={{ scale: 0.95 }}
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            aria-label="Toggle menu"
                        >
                            <svg
                                className="w-6 h-6 text-brown-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d={isMenuOpen
                                        ? "M6 18L18 6M6 6l12 12"
                                        : "M4 6h16M4 12h16M4 18h16"
                                    }
                                />
                            </svg>
                        </motion.button>
                    </div>
                </div>
            </motion.nav>

            {/* Mobile menu */}
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        className="fixed inset-0 z-40 md:hidden"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {/* Backdrop */}
                        <motion.div
                            className="absolute inset-0 bg-black/20 backdrop-blur-sm"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={closeMenu}
                        />

                        {/* Menu panel */}
                        <motion.div
                            className="absolute top-0 right-0 w-full max-w-sm bg-white h-screen shadow-lg"
                            initial={{ x: '100%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%' }}
                            transition={{ type: 'spring', damping: 25, stiffness: 200 }}
                        >
                            <div className="flex flex-col p-6 space-y-4 mt-16">
                                {menuItems.map((item) => (
                                    <motion.a
                                        key={item.name}
                                        href={item.href}
                                        className="px-4 py-3 text-lg font-medium text-brown-700 rounded-lg hover:bg-brown-50"
                                        onClick={closeMenu}
                                        whileHover={{ x: 8 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        {item.name}
                                    </motion.a>
                                ))}
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default Navigation; 
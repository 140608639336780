import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import BeanJournal from './coffee/BeanJournal';
import CoffeeGallery from './coffee/CoffeeGallery';
import CoffeeMap from './coffee/CoffeeMap';

export default function Coffee() {
    const [isJournalOpen, setIsJournalOpen] = useState(false);
    const [isMapOpen, setIsMapOpen] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const MarqueeContent = () => (
        <span className="mx-6">
            Thanks a latte for stopping by &nbsp;&nbsp;•&nbsp;&nbsp;
            Take life one sip at a time &nbsp;&nbsp;•&nbsp;&nbsp;
            Good code mocha me crazy &nbsp;&nbsp;•&nbsp;&nbsp;
            Bean there, done that &nbsp;&nbsp;•&nbsp;&nbsp;
            Did you know? Coffee beans are actually fruit pits! &nbsp;&nbsp;•&nbsp;&nbsp;
            Over 2.25 billion cups of coffee are consumed daily worldwide &nbsp;&nbsp;•&nbsp;&nbsp;
            Brazil produces 40% of the world's coffee &nbsp;&nbsp;•&nbsp;&nbsp;
            Making pour life decisions &nbsp;&nbsp;•&nbsp;&nbsp;
            Coffee: the original social network &nbsp;&nbsp;•&nbsp;&nbsp;
            You mocha me very happy &nbsp;&nbsp;•&nbsp;&nbsp;
            Coffee was discovered by dancing goats &nbsp;&nbsp;•&nbsp;&nbsp;
            Procaffeinating: (n.) the tendency to not start anything until you've had coffee &nbsp;&nbsp;•&nbsp;&nbsp;
            I've bean thinking about you &nbsp;&nbsp;•&nbsp;&nbsp;
            Coffee trees can live up to 100 years &nbsp;&nbsp;•&nbsp;&nbsp;
            What a brew-tiful day &nbsp;&nbsp;•&nbsp;&nbsp;
            Deja brew: the feeling you've had this coffee before &nbsp;&nbsp;•&nbsp;&nbsp;
            First coffee cultivated in Yemen around 1100 AD &nbsp;&nbsp;•&nbsp;&nbsp;
            Livin' la vida mocha &nbsp;&nbsp;•&nbsp;&nbsp;
            The world's most expensive coffee comes from cat poop &nbsp;&nbsp;•&nbsp;&nbsp;
            Better latte than never &nbsp;&nbsp;•&nbsp;&nbsp;
        </span>
    );

    return (
        <section
            id="coffee"
            className="min-h-[90vh] bg-white relative flex flex-col [scroll-margin-top:5rem] bg-notebook-grid overflow-hidden"
        >
            {/* Top Marquee */}
            <div className="relative overflow-hidden py-2 h-8 bg-brown-800">
                <div className="flex whitespace-nowrap font-mono text-brown-100 text-sm tracking-tight absolute animate-marquee left-0">
                    <MarqueeContent />
                    <MarqueeContent />
                </div>
                <div className="flex whitespace-nowrap font-mono text-brown-100 text-sm tracking-tight absolute animate-marquee2 left-0">
                    <MarqueeContent />
                    <MarqueeContent />
                </div>
            </div>

            {/* Section Title */}
            <div className="text-center pt-8 pb-4">
                <h2 className="text-4xl font-display font-bold">Coffee Journey</h2>
            </div>

            {/* Main Content */}
            <div className="flex-1 max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 min-h-0">
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                    className="h-full flex flex-col py-4"
                >
                    <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 overflow-hidden">
                        {/* Coffee Gear Showcase */}
                        <div className="h-[250px] lg:h-[calc(90vh-250px)] min-h-[200px]">
                            <div className="w-full h-full rounded-lg overflow-hidden">
                                <img
                                    src="/coffee_gear.png"
                                    alt="Coffee Gear Showcase"
                                    className="w-full h-full object-contain p-2 sm:p-4"
                                />
                            </div>
                        </div>

                        {/* Coffee Gallery */}
                        <div className="h-[250px] lg:h-[calc(90vh-250px)] min-h-[200px] overflow-hidden">
                            <CoffeeGallery />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="text-center py-4 flex flex-col sm:flex-row gap-3 sm:gap-4 justify-center">
                        <button
                            onClick={() => setIsJournalOpen(true)}
                            className="bg-brown-50 hover:bg-brown-100 text-brown-800 px-6 sm:px-8 py-3 rounded-lg 
                                     transition duration-300 ease-in-out transform hover:scale-105
                                     font-display text-base sm:text-lg border border-brown-200 shadow-sm"
                        >
                            <span className="inline-block mr-2">📔</span>
                            Bean Journal
                        </button>
                        <button
                            onClick={() => setIsMapOpen(true)}
                            className="bg-brown-50 hover:bg-brown-100 text-brown-800 px-6 sm:px-8 py-3 rounded-lg 
                                     transition duration-300 ease-in-out transform hover:scale-105
                                     font-display text-base sm:text-lg border border-brown-200 shadow-sm"
                        >
                            <span className="inline-block mr-2">🗺️</span>
                            Coffee Map
                        </button>
                    </div>
                </motion.div>
            </div>

            {/* Bottom Marquee */}
            <div className="relative overflow-hidden py-2 h-8 bg-brown-800">
                <div className="flex whitespace-nowrap font-mono text-brown-100 text-sm tracking-tight absolute animate-marquee left-0">
                    <MarqueeContent />
                    <MarqueeContent />
                </div>
                <div className="flex whitespace-nowrap font-mono text-brown-100 text-sm tracking-tight absolute animate-marquee2 left-0">
                    <MarqueeContent />
                    <MarqueeContent />
                </div>
            </div>

            {/* Modals */}
            <BeanJournal isOpen={isJournalOpen} onClose={() => setIsJournalOpen(false)} />
            <CoffeeMap isOpen={isMapOpen} onClose={() => setIsMapOpen(false)} />
        </section>
    );
} 
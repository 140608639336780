import { PersonalInfo, Skill, Project, CoffeeBlog, BrewingTechnique, SocialLink } from '../types';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { projects } from './projects';

export const personalInfo: PersonalInfo = {
    name: 'Waleed Hashmi',
    title: 'Software/Machine Learning Engineer & Coffee Enthusiast',
    subtitle: 'Crafting data-driven solutions with technical innovation',
    about: "Senior software engineer with 5+ years of experience architecting ML systems and scalable data infrastructure. Led development of production ML models and data pipelines processing over 1M events/minute at Keyhole. Designed optimization engines delivering 2x revenue growth at Hubpay and pioneered computer vision research resulting in multiple ACM publications. Track record of leading technical initiatives from research through production, including real-time analytics platforms, automated pricing systems, and NLP models achieving 87% accuracy. Proven expertise in distributed systems, MLOps, and full-stack development. CS & Math graduate from NYU (3.8 GPA) with research published at UIST, WebConf, and IMC.",
    highlightedPhrases: [
        'Senior software engineer',
        '5+ years',
        'ML systems',
        'scalable data infrastructure',
        '1M events/minute',
        '2x revenue growth',
        'multiple ACM publications',
        'full-stack development',
        'distributed systems',
        'MLOps',
    ]
};

export const skills: Skill[] = [
    {
        name: 'Core Development',
        technologies: [
            'System Design', 'Data Structures', 'Algorithms', 'OOP',
            'Design Patterns', 'Microservices', 'API Design', 'Testing'
        ]
    },
    {
        name: 'Backend Development',
        technologies: [
            'Python', 'FastAPI', 'Node.js', 'PostgreSQL',
            'gRPC', 'REST APIs', 'GraphQL', 'Flask'
        ]
    },
    {
        name: 'Machine Learning',
        technologies: [
            'PyTorch', 'TensorFlow', 'Scikit-learn', 'BERT',
            'Computer Vision', 'GANs', 'MLflow', 'Hugging Face'
        ]
    },
    {
        name: 'Data Engineering',
        technologies: [
            'SQL', 'BigQuery', 'Spark', 'Kafka',
            'Airflow', 'dbt', 'Redis', 'MongoDB'
        ]
    },
    {
        name: 'Infrastructure & DevOps',
        technologies: [
            'AWS', 'GCP', 'Docker', 'Kubernetes',
            'CI/CD', 'Terraform', 'Git', 'Linux'
        ]
    },
    {
        name: 'Frontend Development',
        technologies: [
            'React', 'TypeScript', 'Redux', 'Next.js',
            'd3.js', 'Tailwind', 'webpack', 'Jest'
        ]
    }
];

export const socialLinks: SocialLink[] = [
    {
        platform: 'linkedin',
        url: 'https://www.linkedin.com/in/waleedhashmi',
        icon: ['fab', 'linkedin']
    },
    {
        platform: 'github',
        url: 'https://github.com/waleedhashmi',
        icon: ['fab', 'github']
    },
    {
        platform: 'email',
        url: 'mailto:waleed@example.com',
        icon: ['fas', 'envelope']
    }
];

// Helper function to get all unique tags from projects
export const getAllProjectTags = (): string[] => {
    const tagsSet = new Set<string>();
    projects.forEach(project => {
        project.tags.forEach(tag => tagsSet.add(tag));
    });
    return Array.from(tagsSet).sort();
};

export { projects };
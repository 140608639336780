import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { socialLinks, personalInfo } from '../data';

const Contact: React.FC = () => {
    return (
        <>
            <section id="contact" className="py-16 bg-white scroll-mt-20">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold text-center mb-12">Let's Connect</h2>
                    <div className="max-w-xl mx-auto">
                        <div className="flex justify-center space-x-6 mb-8">
                            {socialLinks.map((link) => (
                                <a
                                    key={link.platform}
                                    href={link.url}
                                    className="text-gray-600 hover:text-brown-600"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={link.icon} size="2x" />
                                </a>
                            ))}
                        </div>
                        <p className="text-center text-gray-600">
                            Interested in collaborating or just want to chat about coffee?
                            Let's grab a virtual coffee!
                        </p>
                    </div>
                </div>
            </section>

            <footer className="bg-gray-800 text-white py-8">
                <div className="container mx-auto px-6 text-center">
                    <p>© {new Date().getFullYear()} {personalInfo.name}. Built with ☕ and code.</p>
                </div>
            </footer>
        </>
    );
};

export default Contact; 
import React, { useState } from 'react';

/**
 * SIN Validator Component
 * Requirements:
 * 1. Must be exactly 9 digits
 * 2. Must pass Luhn algorithm
 *
 * Time Complexity: O(n) where n is the length of input
 * Space Complexity: O(1) as we only use a fixed amount of extra space
 */
export const SINValidator: React.FC = () => {
    const [sin, setSin] = useState<string>('');
    const [isValidSIN, setIsValidSIN] = useState<boolean | null>(null);

    const isValid = (digits: number[]): boolean => {
        let sum = 0;
        const parity = digits.length % 2;
        for (let i = 0; i < digits.length; i++) {
            if (i % 2 !== parity) {
                sum += digits[i];
            } else if (digits[i] > 4) {
                sum += 2 * digits[i] - 9;
            } else {
                sum += 2 * digits[i];
            }
        }
        return sum % 10 === 0;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const input = e.target.value.replace(/\D/g, '');
        setSin(input);
        if (input.length === 9) {
            setIsValidSIN(isValid(input.split('').map(Number)));
        } else {
            setIsValidSIN(null);
        }
    };

    return (
        <div className="max-w-2xl mx-auto my-5 p-5 pt-20 font-sans">
            <h1 className="text-2xl font-bold text-gray-800">Sharewaves Assignment</h1>
            <div className="text-gray-600 mb-3 pt-5">Enter a SIN number:</div>
            <input
                type="text"
                value={sin}
                onChange={handleInputChange}
                maxLength={9}
                placeholder="e.g., 046454286"
                className="w-48 p-2.5 text-lg border-2 border-gray-300 rounded-md mr-2.5 focus:outline-none focus:border-blue-500"
            />
            <div className={`mt-2.5 font-bold ${isValidSIN ? 'text-green-700' : 'text-red-700'}`}>
                {sin.length === 9 && (isValidSIN ? 'Valid SIN' : 'Invalid SIN')}
                {sin.length !== 9 && sin.length > 0 && 'Invalid - Must be 9 digits'}
            </div>

            <div className="mt-10 p-5 bg-gray-100 rounded-md">
                <h3 className="text-xl font-bold mb-4">Implementation Notes</h3>

                <h4 className="text-lg font-semibold mt-4 mb-2">Algorithm Reference:</h4>
                <ul className="list-disc pl-6 space-y-2 mb-4">
                    <li>Implementation based on Luhn algorithm pseudocode from Wikipedia [<a className="text-blue-500" href='https://en.wikipedia.org/wiki/Luhn_algorithm'>Link</a>]</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">Assumptions Made:</h4>
                <ul className="list-disc pl-6 space-y-2 mb-4">
                    <li>Input should be digits only (non-digits are automatically removed)</li>
                    <li>Validation happens in real-time</li>
                    <li>All combinations of 9 digits are potential valid SINs if they pass the Luhn algorithm</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">Complexities:</h4>
                <ul className="list-disc pl-6 space-y-2 mb-4">
                    <li>Time Complexity: O(n) where n is the length of input</li>
                    <li>Space Complexity: O(1) as we only use a fixed amount of extra space</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">Future Improvements:</h4>
                <ul className="list-disc pl-6 space-y-2 mb-4">
                    <li>Build out a comprehensive test suite using Jest and React Testing Library to cover:
                        <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-600">
                            <li>Edge cases like repeated digits and boundary values</li>
                            <li>User interaction flows including keyboard input</li>
                            <li>Error scenarios and validation feedback</li>
                        </ul>
                    </li>
                    <li>Input handling capabilities:
                        <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-600">
                            <li>Support for copy/paste of formatted numbers (e.g., "123-456-789")</li>
                            <li>Bulk validation for multiple SINs at once</li>
                            <li>Format input automatically while typing (XXX-XXX-XXX)</li>
                        </ul>
                    </li>
                    <li>Strengthen error handling and security:
                        <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-600">
                            <li>Implement proper error boundaries for React component crashes</li>
                            <li>If moving validation to backend: add CSRF protection, input sanitization, and rate limiting</li>
                            <li>Add proper error messages for different validation scenarios</li>
                        </ul>
                    </li>
                    <li>Add monitoring and logging features.</li>
                    <li>User experience improvements:
                        <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-600">
                            <li>Add keyboard shortcuts for quick clearing/validation</li>
                            <li>Implement undo/redo functionality for input changes</li>
                            <li>Add visual feedback during validation process</li>
                        </ul>
                    </li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">Time taken: ~1 hr</h4>

                <h4 className="text-lg font-semibold mt-4 mb-2">How to Run:</h4>
                <ul className="list-disc pl-6 space-y-2">
                    <li>Save the file as SINValidator.tsx in your project's components directory</li>
                    <li>Ensure your project has React and Tailwind CSS installed</li>
                    <li>Import and use the component in your application:
                        <code className="block bg-gray-800 text-white p-2 rounded-md mt-2 mb-2 text-sm">
                            import {'{'} SINValidator {'}'} from './components/SINValidator';<br />
                            {'<SINValidator />'}
                        </code>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SINValidator;
import React, { useState } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { motion, AnimatePresence } from 'framer-motion';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

interface Coffee {
    id: number;
    name: string;
    roaster: string;
    origin: string;
    type: string;
    process: string;
    lastTasted: string;
    roastLevel: number;
    brewMethod: string;
    rating: number;
    description: string;
    personalNotes: string;
    notes: {
        acidity: number;
        body: number;
        fruity: number;
        sweet: number;
        nutty: number;
    };
}

const sampleCoffees: Coffee[] = [
    {
        id: 1,
        name: "La Esperanza",
        roaster: "49th Parallel Coffee Roasters",
        origin: "Huila, Colombia",
        type: "Single Origin",
        process: "Washed",
        lastTasted: "2024-05-15",
        roastLevel: 2.5,
        brewMethod: "V60 Pour Over",
        rating: 4.5,
        description: "Notes of milk chocolate, caramel, and ripe stone fruit with a smooth, buttery body.",
        personalNotes: "Rich, balanced cup. Perfect morning coffee. Great value for the quality.",
        notes: {
            acidity: 3.5,
            body: 4.0,
            fruity: 4.0,
            sweet: 4.5,
            nutty: 3.0,
        },
    },
    {
        id: 2,
        name: "Mount Pleasant",
        roaster: "Prototype Coffee",
        origin: "Blend - Ethiopia/Colombia",
        type: "Blend",
        process: "Mixed",
        lastTasted: "2024-08-14",
        roastLevel: 3,
        brewMethod: "AeroPress",
        rating: 4.0,
        description: "A comfortable medium roast with notes of dark chocolate, cherry, and brown sugar.",
        personalNotes: "Versatile blend that works well with multiple brew methods. Great afternoon pick.",
        notes: {
            acidity: 3.0,
            body: 3.5,
            fruity: 3.5,
            sweet: 4.0,
            nutty: 3.5,
        },
    },
    {
        id: 3,
        name: "Ethiopia Guji Natural",
        roaster: "Luna Coffee",
        origin: "Guji Zone, Ethiopia",
        type: "Single Origin",
        process: "Natural",
        lastTasted: "2024-03-23",
        roastLevel: 2,
        brewMethod: "Kalita Wave",
        rating: 4.8,
        description: "Vibrant and complex with distinct blueberry, jasmine, and honey notes.",
        personalNotes: "Stunning natural process. Berry bomb. Best as pour over to highlight complexity.",
        notes: {
            acidity: 4.5,
            body: 3.5,
            fruity: 5.0,
            sweet: 4.5,
            nutty: 1.0,
        },
    },
    {
        id: 4,
        name: "Hideout Blend",
        roaster: "Pallet Coffee Roasters",
        origin: "Blend - Brazil/Guatemala",
        type: "Blend",
        process: "Mixed",
        lastTasted: "2024-10-12",
        roastLevel: 3.5,
        brewMethod: "Espresso",
        rating: 4.2,
        description: "Rich and balanced with notes of dark chocolate, toasted nuts, and caramel.",
        personalNotes: "Excellent espresso blend. Great crema, forgiving extraction.",
        notes: {
            acidity: 2.5,
            body: 4.5,
            fruity: 2.5,
            sweet: 4.0,
            nutty: 4.5,
        },
    },
    {
        id: 5,
        name: "Kenya Karimikui",
        roaster: "Nemesis Coffee",
        origin: "Kirinyaga, Kenya",
        type: "Single Origin",
        process: "Washed",
        lastTasted: "2024-06-11",
        roastLevel: 2,
        brewMethod: "V60 Pour Over",
        rating: 4.7,
        description: "Bright and juicy with blackcurrant, pomegranate, and brown sugar notes.",
        personalNotes: "Classic Kenya profile. Incredibly clean and bright. Worth the premium price.",
        notes: {
            acidity: 5.0,
            body: 3.0,
            fruity: 4.5,
            sweet: 3.5,
            nutty: 1.5,
        },
    },
    {
        id: 6,
        name: "Strathcona",
        roaster: "Moving Coffee",
        origin: "Blend - Ethiopia/Colombia/Brazil",
        type: "Blend",
        process: "Mixed",
        lastTasted: "2024-04-10",
        roastLevel: 3,
        brewMethod: "French Press",
        rating: 4.0,
        description: "Well-rounded with notes of milk chocolate, orange, and toasted almonds.",
        personalNotes: "Great daily drinker. Works well with milk. Good value blend.",
        notes: {
            acidity: 3.5,
            body: 4.0,
            fruity: 3.5,
            sweet: 4.0,
            nutty: 4.0,
        },
    },
    {
        id: 7,
        name: "Panama Geisha",
        roaster: "Timbertrain Coffee Roasters",
        origin: "Boquete, Panama",
        type: "Single Origin",
        process: "Washed",
        lastTasted: "2024-09-19",
        roastLevel: 1.5,
        brewMethod: "V60 Pour Over",
        rating: 5.0,
        description: "Elegant and complex with jasmine, bergamot, and peach notes.",
        personalNotes: "Exceptional complexity. Tea-like body. Best brewed with filtered water.",
        notes: {
            acidity: 4.0,
            body: 2.5,
            fruity: 4.5,
            sweet: 4.5,
            nutty: 1.0,
        },
    },
    {
        id: 8,
        name: "Commercial Drive Blend",
        roaster: "Continental Coffee",
        origin: "Blend - Colombia/Guatemala/Ethiopia",
        type: "Blend",
        process: "Mixed",
        lastTasted: "2024-07-18",
        roastLevel: 3.5,
        brewMethod: "Moka Pot",
        rating: 3.8,
        description: "Full-bodied with notes of dark chocolate, walnut, and subtle citrus.",
        personalNotes: "Solid everyday coffee. Great with milk. Good price point.",
        notes: {
            acidity: 2.5,
            body: 4.5,
            fruity: 2.5,
            sweet: 3.5,
            nutty: 4.5,
        },
    },
    {
        id: 9,
        name: "Costa Rica Las Lajas",
        roaster: "Agro Coffee Roasters",
        origin: "Central Valley, Costa Rica",
        type: "Single Origin",
        process: "Honey",
        lastTasted: "2024-11-07",
        roastLevel: 2.5,
        brewMethod: "Chemex",
        rating: 4.4,
        description: "Clean and sweet with honey, red apple, and caramel notes.",
        personalNotes: "Beautiful honey process. Great clarity in Chemex. Very clean cup.",
        notes: {
            acidity: 4.0,
            body: 3.5,
            fruity: 4.0,
            sweet: 4.5,
            nutty: 2.5,
        },
    },
    {
        id: 10,
        name: "East Van Blend",
        roaster: "East Van Roasters",
        origin: "Blend - Peru/Ethiopia",
        type: "Blend",
        process: "Mixed",
        lastTasted: "2024-02-16",
        roastLevel: 3,
        brewMethod: "Espresso",
        rating: 4.1,
        description: "Balanced and smooth with notes of chocolate, berries, and toasted nuts.",
        personalNotes: "Very forgiving espresso blend. Works great in milk drinks.",
        notes: {
            acidity: 3.0,
            body: 4.0,
            fruity: 3.5,
            sweet: 4.0,
            nutty: 4.0,
        },
    },
    {
        id: 11,
        name: "Burundi Gatare",
        roaster: "House of Funk Coffee",
        origin: "Kayanza, Burundi",
        type: "Single Origin",
        process: "Washed",
        lastTasted: "2024-01-15",
        roastLevel: 2,
        brewMethod: "V60 Pour Over",
        rating: 4.6,
        description: "Bright and complex with notes of raspberry, orange blossom, and brown sugar.",
        personalNotes: "Unique and complex. Great example of Burundi coffee. Very clean finish.",
        notes: {
            acidity: 4.5,
            body: 3.0,
            fruity: 4.5,
            sweet: 4.0,
            nutty: 2.0,
        },
    },
    {
        id: 12,
        name: "Lions Gate Blend",
        roaster: "Moja Coffee",
        origin: "Blend - Brazil/Honduras/Ethiopia",
        type: "Blend",
        process: "Mixed",
        lastTasted: "2024-08-24",
        roastLevel: 3.5,
        brewMethod: "Batch Brew",
        rating: 3.9,
        description: "Rich and full-bodied with notes of dark chocolate, toffee, and roasted nuts.",
        personalNotes: "Great office coffee. Consistent in batch brew. Good value.",
        notes: {
            acidity: 2.5,
            body: 4.5,
            fruity: 2.5,
            sweet: 4.0,
            nutty: 4.5,
        },
    },
    {
        id: 13,
        name: "Guatemala Huehuetenango",
        roaster: "Milano Coffee Roasters",
        origin: "Huehuetenango, Guatemala",
        type: "Single Origin",
        process: "Washed",
        lastTasted: "2024-05-23",
        roastLevel: 3,
        brewMethod: "AeroPress",
        rating: 4.2,
        description: "Balanced with notes of milk chocolate, orange, and honey.",
        personalNotes: "Classic Guatemala profile. Very versatile. Great for any time of day.",
        notes: {
            acidity: 3.5,
            body: 4.0,
            fruity: 3.5,
            sweet: 4.0,
            nutty: 3.5,
        },
    },
    {
        id: 14,
        name: "Rwanda Mukashyaka",
        roaster: "Kafka's Coffee Roasting",
        origin: "Nyamagabe, Rwanda",
        type: "Single Origin",
        process: "Washed",
        lastTasted: "2024-10-22",
        roastLevel: 2.5,
        brewMethod: "Kalita Wave",
        rating: 4.3,
        description: "Complex with notes of black tea, red currant, and maple syrup.",
        personalNotes: "Delicate and tea-like. Very clean. Best with soft water.",
        notes: {
            acidity: 4.0,
            body: 3.0,
            fruity: 4.0,
            sweet: 4.0,
            nutty: 2.0,
        },
    },
    {
        id: 15,
        name: "Lookout Blend",
        roaster: "Revolver Coffee",
        origin: "Blend - Colombia/Ethiopia/Indonesia",
        type: "Blend",
        process: "Mixed",
        lastTasted: "2024-11-21",
        roastLevel: 3,
        brewMethod: "Espresso",
        rating: 4.0,
        description: "Well-balanced with notes of dark chocolate, cherry, and caramel.",
        personalNotes: "Solid espresso blend. Consistent shots. Good milk chocolate notes.",
        notes: {
            acidity: 3.0,
            body: 4.0,
            fruity: 3.5,
            sweet: 4.5,
            nutty: 3.5,
        },
    }
];

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function BeanJournal({ isOpen, onClose }: Props) {
    const [selectedCoffee, setSelectedCoffee] = useState(sampleCoffees[0]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const chartData = {
        labels: ['Acidity', 'Body', 'Fruity', 'Sweet', 'Nutty'],
        datasets: [
            {
                label: 'Flavor Profile',
                data: Object.values(selectedCoffee.notes),
                backgroundColor: 'rgba(139, 69, 19, 0.2)',
                borderColor: '#8B4513',
                borderWidth: 2,
                pointBackgroundColor: '#8B4513',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#8B4513'
            },
        ],
    };

    const chartOptions = {
        scales: {
            r: {
                angleLines: {
                    color: 'rgba(139, 69, 19, 0.1)',
                },
                grid: {
                    color: 'rgba(139, 69, 19, 0.1)',
                },
                pointLabels: {
                    color: '#8B4513',
                    font: {
                        size: 12,
                        family: "'Inter var', sans-serif",
                    },
                },
                ticks: {
                    display: false,
                    maxTicksLimit: 5,
                },
                suggestedMin: 0,
                suggestedMax: 5,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
                    onClick={onClose}
                >
                    <motion.div
                        initial={{ scale: 0.9 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.9 }}
                        className="bg-white rounded-2xl p-4 sm:p-6 w-full mx-auto h-[90vh] flex flex-col relative overflow-hidden"
                        style={{ maxWidth: '95vw' }}
                        onClick={e => e.stopPropagation()}
                    >
                        <button
                            onClick={onClose}
                            className="absolute top-4 right-4 p-2 hover:bg-brown-50 rounded-full transition-colors z-10"
                        >
                            <svg className="w-6 h-6 text-brown-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <button
                            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                            className="lg:hidden mb-4 flex items-center text-brown-800 font-medium"
                        >
                            <svg
                                className="w-6 h-6 mr-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d={isSidebarOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                                />
                            </svg>
                            {isSidebarOpen ? 'Close Menu' : 'Select Coffee'}
                        </button>

                        <div className="flex-1 grid grid-cols-1 lg:grid-cols-12 gap-6 overflow-y-auto">
                            <AnimatePresence>
                                {(isSidebarOpen || window.innerWidth >= 1024) && (
                                    <motion.div
                                        initial={{ x: -300, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: -300, opacity: 0 }}
                                        className="fixed lg:relative lg:col-span-4 bg-brown-50 rounded-xl p-4 flex flex-col h-[calc(100vh-2rem)] lg:h-auto"
                                        style={{
                                            top: isSidebarOpen ? '1rem' : 'auto',
                                            left: isSidebarOpen ? '1rem' : 'auto',
                                            width: isSidebarOpen ? 'calc(100vw - 2rem)' : 'auto',
                                            maxWidth: isSidebarOpen ? '300px' : 'none',
                                            zIndex: isSidebarOpen ? 20 : 'auto',
                                        }}
                                    >
                                        <h3 className="text-xl font-display font-bold text-brown-800 mb-4 px-2">
                                            Coffee Collection
                                        </h3>
                                        <div className="overflow-y-auto flex-1 pr-2">
                                            <div className="space-y-3">
                                                {sampleCoffees.map(coffee => (
                                                    <motion.div
                                                        key={coffee.id}
                                                        className={`p-4 rounded-lg cursor-pointer transition-all duration-300 ${selectedCoffee.id === coffee.id
                                                            ? 'bg-white shadow-md border-2 border-brown-200'
                                                            : 'bg-brown-50 hover:bg-white hover:shadow-sm'
                                                            }`}
                                                        onClick={() => {
                                                            setSelectedCoffee(coffee);
                                                            if (window.innerWidth < 1024) {
                                                                setIsSidebarOpen(false);
                                                            }
                                                        }}
                                                        whileHover={{ scale: 1.02 }}
                                                        whileTap={{ scale: 0.98 }}
                                                    >
                                                        <div>
                                                            <h4 className="font-semibold text-brown-800">{coffee.name}</h4>
                                                            <p className="text-sm text-brown-600">{coffee.roaster}</p>
                                                            <p className="text-xs text-brown-500 mt-1">{coffee.origin}</p>
                                                        </div>
                                                    </motion.div>
                                                ))}
                                            </div>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>

                            <div className="lg:col-span-8 flex flex-col gap-6">
                                <div className="bg-white rounded-xl p-6 shadow-sm">
                                    <div className="flex items-center justify-between mb-2">
                                        <h3 className="text-2xl font-display font-bold text-brown-800">
                                            {selectedCoffee.name}
                                        </h3>
                                        <div className="flex items-center">
                                            <span className="text-2xl mr-1">⭐</span>
                                            <span className="font-medium text-brown-700">{selectedCoffee.rating}</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center text-brown-600 text-sm mb-4">
                                        <span className="font-medium">{selectedCoffee.roaster}</span>
                                        <span className="mx-2">•</span>
                                        <span>Last tasted {new Date(selectedCoffee.lastTasted).toLocaleDateString()}</span>
                                    </div>
                                    <p className="text-sm text-brown-600 italic mb-4">"{selectedCoffee.description}"</p>
                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="text-center">
                                            <span className="text-2xl mb-1 block">☕</span>
                                            <p className="text-xs font-medium text-brown-700">{selectedCoffee.brewMethod}</p>
                                        </div>
                                        <div className="text-center">
                                            <span className="text-2xl mb-1 block">🌱</span>
                                            <p className="text-xs font-medium text-brown-700">{selectedCoffee.process}</p>
                                        </div>
                                        <div className="text-center">
                                            <span className="text-2xl mb-1 block">🌍</span>
                                            <p className="text-xs font-medium text-brown-700">{selectedCoffee.type}</p>
                                        </div>
                                        <div className="text-center">
                                            <span className="text-2xl mb-1 block">📅</span>
                                            <p className="text-xs font-medium text-brown-700">
                                                {selectedCoffee.lastTasted}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div className="bg-brown-50 rounded-xl p-4">
                                        <div className="space-y-4">
                                            <div className="bg-white rounded-xl p-6 shadow-sm">
                                                <h4 className="font-display font-semibold text-brown-800 mb-3">Roast Level</h4>
                                                <div className="h-4 bg-brown-200 rounded-full overflow-hidden">
                                                    <div
                                                        className="h-full bg-gradient-to-r from-brown-400 to-brown-600 rounded-full transition-all duration-300"
                                                        style={{ width: `${(selectedCoffee.roastLevel / 5) * 100}%` }}
                                                    />
                                                </div>
                                                <div className="flex justify-between text-sm mt-2 text-brown-600">
                                                    <span>Light</span>
                                                    <span>Medium</span>
                                                    <span>Dark</span>
                                                </div>
                                            </div>

                                            <div className="bg-white rounded-xl p-6 shadow-sm">
                                                <h4 className="font-display font-semibold text-brown-800 mb-3">Origin Details</h4>
                                                <div className="space-y-3">
                                                    <div>
                                                        <p className="text-sm font-medium text-brown-700">Region</p>
                                                        <p className="text-sm text-brown-600">{selectedCoffee.origin}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-medium text-brown-700">Process Method</p>
                                                        <p className="text-sm text-brown-600">{selectedCoffee.process}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-medium text-brown-700">Coffee Type</p>
                                                        <p className="text-sm text-brown-600">{selectedCoffee.type}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="bg-white rounded-xl p-6 shadow-sm">
                                                <h4 className="font-display font-semibold text-brown-800 mb-3">Personal Notes</h4>
                                                <p className="text-sm text-brown-600">{selectedCoffee.personalNotes}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-brown-50 rounded-xl p-4">
                                        <div className="space-y-4">
                                            <div className="bg-white rounded-xl p-6 shadow-sm">
                                                <h4 className="font-display font-semibold text-brown-800 mb-4">Flavor Profile</h4>
                                                <div className="aspect-square">
                                                    <Radar data={chartData} options={chartOptions} />
                                                </div>
                                            </div>
                                            <div className="bg-white rounded-xl p-6 shadow-sm">
                                                <h4 className="font-display font-semibold text-brown-800 mb-3">Tasting Score</h4>
                                                <div className="flex items-center justify-center space-x-2">
                                                    <span className="text-4xl">⭐</span>
                                                    <span className="text-3xl font-bold text-brown-800">{selectedCoffee.rating}</span>
                                                    <span className="text-brown-600">/5.0</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Project } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLink, faTimes } from '@fortawesome/free-solid-svg-icons';

interface ExpandedProjectProps {
    project: Project;
    isOpen: boolean;
    onClose: () => void;
}

const extractSections = (richText: string) => {
    const sections: { title: string; items: string[] }[] = [];
    const h3Matches = richText.match(/<h3>.*?<\/h3>/g) || [];

    h3Matches.forEach(h3 => {
        const title = h3.replace(/<\/?h3>/g, '');
        const sectionStart = richText.indexOf(h3) + h3.length;
        const nextH3 = richText.indexOf('<h3>', sectionStart);
        const sectionEnd = nextH3 === -1 ? richText.length : nextH3;
        const sectionContent = richText.slice(sectionStart, sectionEnd);

        const listItems = sectionContent.match(/<li>.*?<\/li>/g) || [];
        const items = listItems.map(item => item.replace(/<\/?li>/g, '').trim());

        if (items.length > 0) {
            sections.push({ title, items });
        }
    });

    return sections;
};

const ExpandedProject: React.FC<ExpandedProjectProps> = ({ project, isOpen, onClose }) => {
    const sections = project.details?.richText ? extractSections(project.details.richText) : [];

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog onClose={onClose} className="relative z-50">
                {/* Backdrop */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/40 backdrop-blur-sm" />
                </Transition.Child>

                {/* Modal */}
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-4xl bg-white rounded-2xl shadow-2xl">
                                {/* Close Button */}
                                <button
                                    onClick={onClose}
                                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 z-20
                                        p-2 rounded-full hover:bg-gray-100 transition-colors"
                                >
                                    <FontAwesomeIcon icon={faTimes} className="w-5 h-5" />
                                </button>

                                {/* Content */}
                                <div className="overflow-hidden rounded-2xl">
                                    {/* Header with Image and Title */}
                                    <div className="flex flex-col md:flex-row md:items-start gap-6 p-6 md:p-8 bg-gradient-to-br from-brown-50 to-white border-b border-brown-100">
                                        {/* Image Container */}
                                        <div className="relative w-full md:w-72 h-48 md:h-44 flex-shrink-0 rounded-xl overflow-hidden shadow-lg">
                                            <img
                                                src={`/project_thumbnails/${project.id}.png`}
                                                alt={project.title}
                                                className="w-full h-full object-cover"
                                            />
                                            {project.employer && (
                                                <a
                                                    href={project.employer.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="absolute top-3 left-3 transition-transform hover:scale-105"
                                                >
                                                    <img
                                                        src={`/employer_logos/${project.employer.logo}`}
                                                        alt={project.employer.name}
                                                        className="h-7 rounded-lg shadow-md"
                                                    />
                                                </a>
                                            )}
                                        </div>

                                        {/* Title and Basic Info */}
                                        <div className="flex-1">
                                            <div className="flex flex-wrap gap-2 mb-4">
                                                {project.areas.map(area => (
                                                    <span
                                                        key={area}
                                                        className="px-3 py-1 bg-brown-100/50 text-brown-800 rounded-full text-xs font-medium
                                                            border border-brown-200/50"
                                                    >
                                                        {area.charAt(0).toUpperCase() + area.slice(1)}
                                                    </span>
                                                ))}
                                            </div>

                                            <div className="flex justify-between items-start gap-4 mb-4">
                                                <div>
                                                    <Dialog.Title className="text-2xl md:text-3xl font-bold text-brown-900 mb-2">
                                                        {project.title}
                                                    </Dialog.Title>
                                                    {project.details?.timeline && (
                                                        <p className="text-sm text-brown-500 font-medium">
                                                            {project.details.timeline.start} - {project.details.timeline.end}
                                                        </p>
                                                    )}
                                                </div>
                                                {project.action?.type === 'link' && project.action.url && (
                                                    <a
                                                        href={project.action.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="inline-flex items-center gap-2 px-5 py-2.5 rounded-xl
                                                            bg-brown-600 text-white hover:bg-brown-700 transition-all
                                                            shadow-sm hover:shadow-md font-medium"
                                                    >
                                                        {project.action.label}
                                                    </a>
                                                )}
                                            </div>

                                            <p className="text-brown-700 text-lg mb-5 leading-relaxed">
                                                {project.description}
                                            </p>

                                            <div className="flex flex-wrap gap-2">
                                                {project.tags.map((tag) => (
                                                    <span
                                                        key={tag}
                                                        className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm
                                                            border border-gray-200/50"
                                                    >
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Main Content */}
                                    <div className="p-6 md:p-8 space-y-8">
                                        {project.details?.overview && (
                                            <div className="text-lg text-brown-700 leading-relaxed">
                                                {project.details.overview}
                                            </div>
                                        )}

                                        {/* Rich Text Sections */}
                                        {sections.map((section, sectionIndex) => (
                                            <div
                                                key={sectionIndex}
                                                className="bg-gradient-to-br from-brown-50/50 via-white to-brown-50/30 rounded-xl p-6 border border-brown-100/50"
                                            >
                                                <h3 className="text-xl font-semibold mb-4 text-brown-900">{section.title}</h3>
                                                <div className="grid sm:grid-cols-2 gap-4">
                                                    {section.items.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex items-start gap-3 p-4 rounded-lg bg-white/50 hover:bg-white 
                                                                border border-brown-100/30 transition-all hover:shadow-sm"
                                                        >
                                                            <div className="flex-shrink-0 w-6 h-6 rounded-full bg-gradient-to-br from-brown-100 to-brown-200 
                                                                flex items-center justify-center shadow-sm">
                                                                <span className="text-brown-700 text-xs font-semibold">
                                                                    {index + 1}
                                                                </span>
                                                            </div>
                                                            <p className="flex-1 text-brown-700 leading-relaxed text-sm">
                                                                {item}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}

                                        {/* Challenges & Solutions */}
                                        <div className="grid md:grid-cols-2 gap-6">
                                            {project.details?.challenges && (
                                                <div className="bg-brown-50/50 rounded-xl p-6 border border-brown-100/50">
                                                    <h3 className="text-xl font-semibold mb-4 text-brown-900">Challenges</h3>
                                                    <ul className="space-y-3">
                                                        {project.details.challenges.map((challenge, index) => (
                                                            <li key={index} className="text-brown-700 flex items-start">
                                                                <span className="text-brown-400 mr-3 mt-1.5">●</span>
                                                                <span className="leading-relaxed">{challenge}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            {project.details?.solutions && (
                                                <div className="bg-brown-50/50 rounded-xl p-6 border border-brown-100/50">
                                                    <h3 className="text-xl font-semibold mb-4 text-brown-900">Solutions</h3>
                                                    <ul className="space-y-3">
                                                        {project.details.solutions.map((solution, index) => (
                                                            <li key={index} className="text-brown-700 flex items-start">
                                                                <span className="text-brown-400 mr-3 mt-1.5">●</span>
                                                                <span className="leading-relaxed">{solution}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>

                                        {/* Impact */}
                                        {project.details?.impact && (
                                            <div className="bg-gradient-to-br from-brown-50/50 to-transparent rounded-xl p-6 border border-brown-100/50">
                                                <h3 className="text-xl font-semibold mb-4 text-brown-900">Impact</h3>
                                                <ul className="space-y-3">
                                                    {project.details.impact.map((item, index) => (
                                                        <li key={index} className="text-brown-700 flex items-start">
                                                            <span className="text-brown-400 mr-3 mt-1.5">●</span>
                                                            <span className="leading-relaxed">{item}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}

                                        {/* Technologies */}
                                        {project.details?.technologies && (
                                            <div>
                                                <h3 className="text-xl font-semibold mb-6 text-brown-900">Technologies Used</h3>
                                                <div className="grid md:grid-cols-2 gap-6">
                                                    {project.details.technologies.map((tech, index) => (
                                                        <div key={index} className="bg-gray-50 rounded-xl p-5 border border-gray-100">
                                                            <h4 className="text-base font-semibold text-brown-800 mb-3">
                                                                {tech.category}
                                                            </h4>
                                                            <div className="flex flex-wrap gap-2">
                                                                {tech.items.map((item, i) => (
                                                                    <span
                                                                        key={i}
                                                                        className="px-3 py-1.5 bg-white text-brown-700 rounded-full text-sm
                                                                            border border-gray-200/50 shadow-sm"
                                                                    >
                                                                        {item}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {/* Links */}
                                        {project.details?.links && (
                                            <div className="flex flex-wrap gap-3 pt-4">
                                                {project.details.links.map((link, index) => (
                                                    <a
                                                        key={index}
                                                        href={link.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="inline-flex items-center gap-2.5 px-5 py-2.5 rounded-xl
                                                            bg-gray-100 text-gray-700 hover:bg-gray-200 transition-all
                                                            border border-gray-200/50 shadow-sm hover:shadow-md"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={link.type === 'github' ? faGithub : faLink}
                                                            className="w-4 h-4"
                                                        />
                                                        {link.label}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ExpandedProject; 
import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SINValidator from './components/SINValidator';
import Modal from './components/Modal';
import Home from './components/Home';

// Add Font Awesome icons to library
library.add(fab, faLinkedin, faGithub, faEnvelope);

function App() {
  const [showSINValidator, setShowSINValidator] = useState(false);

  useEffect(() => {
    // Handle hash change
    const handleHashChange = () => {
      setShowSINValidator(window.location.hash === '#sin-validator');
    };

    // Check initial hash
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const handleCloseModal = () => {
    window.location.hash = '';
    setShowSINValidator(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className={showSINValidator ? 'blur-sm' : ''}>
        <Home />
      </div>
      <Modal isOpen={showSINValidator} onClose={handleCloseModal}>
        <SINValidator />
      </Modal>
    </div>
  );
}

export default App;

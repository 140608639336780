import React, { useState } from 'react';
import { Project } from '../../types';
import ExpandedProject from './ExpandedProject';

interface ProjectCardProps {
    project: Project;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [useDefaultImage, setUseDefaultImage] = useState(false);

    const imageSrc = useDefaultImage ? project.image : `/project_thumbnails/${project.id}.png`;

    return (
        <>
            <div
                className="group bg-white rounded-lg shadow-md overflow-hidden relative
                    transform transition-all duration-300 ease-in-out hover:shadow-xl cursor-pointer
                    w-full max-w-[400px]"
                onClick={() => setIsModalOpen(true)}
            >
                {/* Employer Logo */}
                {project.employer && (
                    <a
                        href={project.employer.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="absolute top-4 right-4 z-10 transition-all duration-200 ease-in-out
                            hover:scale-105 hover:-translate-y-0.5"
                        title={project.employer.name}
                        onClick={(e) => e.stopPropagation()} // Prevent modal from opening when clicking logo
                    >
                        <img
                            src={`/employer_logos/${project.employer.logo}`}
                            alt={`${project.employer.name} logo`}
                            className="h-8 w-auto object-contain rounded-lg bg-white shadow-lg
                                transition-all duration-200 ease-in-out hover:shadow-xl"
                        />
                    </a>
                )}

                {/* Project Image */}
                <div className="relative w-full" style={{ paddingBottom: '50%' }}>
                    <img
                        src={imageSrc}
                        alt={project.title}
                        className="absolute inset-0 w-full h-full object-cover transform transition-transform duration-300
                            group-hover:scale-105"
                        onError={() => setUseDefaultImage(true)}
                    />
                </div>

                <div className="p-6">
                    <div className="flex gap-2 mb-3">
                        {project.areas.map(area => (
                            <span
                                key={area}
                                className="px-2 py-1 bg-brown-50 text-brown-700 rounded-md text-xs font-medium
                                    transition-colors duration-300 group-hover:bg-brown-100"
                            >
                                {area.charAt(0).toUpperCase() + area.slice(1)}
                            </span>
                        ))}
                    </div>
                    <h3 className="text-xl font-semibold mb-2 text-gray-800 transition-colors duration-300
                        group-hover:text-brown-600">{project.title}</h3>
                    <p className="text-gray-600 mb-4">
                        {project.description}
                    </p>
                    <div className="flex flex-wrap gap-2">
                        {project.tags.map((tag) => (
                            <span
                                key={tag}
                                className="px-3 py-1 bg-gray-100 rounded-full text-sm
                                    transition-colors duration-300 group-hover:bg-gray-200"
                            >
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            <ExpandedProject
                project={project}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
};

export default ProjectCard; 
import React, { useState, useMemo } from 'react';
import { projects, getAllProjectTags } from '../data';
import { ProjectArea, ProjectFilters } from '../types';
import AreaFilter from './work/AreaFilter';
import TagSelect from './work/TagSelect';
import ProjectCard from './work/ProjectCard';
import Pagination from './work/Pagination';

const PROJECTS_PER_PAGE = 8;

const Work: React.FC = () => {
    const [filters, setFilters] = useState<ProjectFilters>({
        selectedArea: null,
        selectedTags: [],
        currentPage: 1
    });

    const allTags = useMemo(() => getAllProjectTags(), []);

    const filteredProjects = useMemo(() => {
        return projects.filter(project => {
            const areaMatch = !filters.selectedArea || project.areas.includes(filters.selectedArea);
            const tagsMatch = filters.selectedTags.length === 0 ||
                filters.selectedTags.every(tag => project.tags.includes(tag));
            return areaMatch && tagsMatch;
        });
    }, [filters.selectedArea, filters.selectedTags]);

    const totalPages = Math.ceil(filteredProjects.length / PROJECTS_PER_PAGE);
    const currentProjects = filteredProjects.slice(
        (filters.currentPage - 1) * PROJECTS_PER_PAGE,
        filters.currentPage * PROJECTS_PER_PAGE
    );

    const handleAreaClick = (area: ProjectArea) => {
        setFilters(prev => ({
            ...prev,
            selectedArea: prev.selectedArea === area ? null : area,
            currentPage: 1
        }));
    };

    const clearFilters = () => {
        setFilters({
            selectedArea: null,
            selectedTags: [],
            currentPage: 1
        });
    };

    return (
        <section id="projects" className="py-16 bg-gray-50 scroll-mt-20">
            <div className="container mx-auto px-6">
                <h2 className="text-3xl font-bold text-center mb-12">Projects</h2>

                {/* Filters Section */}
                <div className="bg-white rounded-lg shadow-sm p-4 mb-8">
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                        <div className="w-full md:w-auto">
                            <AreaFilter
                                selectedArea={filters.selectedArea}
                                onAreaClick={handleAreaClick}
                                hasFilters={!!filters.selectedArea || filters.selectedTags.length > 0}
                                onClearFilters={clearFilters}
                            />
                        </div>
                        <div className="w-full md:w-72">
                            <TagSelect
                                allTags={allTags}
                                selectedTags={filters.selectedTags}
                                onChange={(tags) => setFilters(prev => ({ ...prev, selectedTags: tags, currentPage: 1 }))}
                            />
                        </div>
                    </div>
                    {(filters.selectedArea || filters.selectedTags.length > 0) && (
                        <div className="mt-4 pt-4 border-t">
                            <div className="flex items-center justify-between">
                                <div className="text-sm text-gray-600">
                                    {filteredProjects.length} project{filteredProjects.length !== 1 ? 's' : ''} found
                                </div>
                                <button
                                    onClick={clearFilters}
                                    className="text-brown-600 hover:text-brown-700 text-sm font-medium"
                                >
                                    Clear all filters
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {/* Projects Grid */}
                <div className="max-w-[1800px] mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 justify-items-center">
                    {currentProjects.map((project) => (
                        <ProjectCard key={project.id} project={project} />
                    ))}
                </div>

                <Pagination
                    currentPage={filters.currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setFilters(prev => ({ ...prev, currentPage: page }))}
                />
            </div>
        </section>
    );
};

export default Work; 
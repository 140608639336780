export const calculateDuration = (startDate: Date, endDate: Date | null = null): string => {
    const end = endDate || new Date();
    const diffInMonths = (end.getFullYear() - startDate.getFullYear()) * 12 +
        (end.getMonth() - startDate.getMonth());

    const years = Math.floor(diffInMonths / 12);
    const months = diffInMonths % 12;

    if (years === 0) {
        return `${months} mo${months !== 1 ? 's' : ''}`;
    } else if (months === 0) {
        return `${years} yr${years !== 1 ? 's' : ''}`;
    } else {
        return `${years} yr${years !== 1 ? 's' : ''} ${months} mo${months !== 1 ? 's' : ''}`;
    }
};

export const formatDateRange = (startDateStr: string, endDateStr: string | null = null): string => {
    const startDate = new Date(startDateStr);
    const endDate = endDateStr ? new Date(endDateStr) : null;

    const formatDate = (date: Date) => {
        return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    };

    const duration = calculateDuration(startDate, endDate);

    return `${formatDate(startDate)} - ${endDate ? formatDate(endDate) : 'Present'} · ${duration}`;
}; 
import React from 'react';
import Hero from './Hero';
import About from './About';
import Work from './Work';
import WorkHistory from './WorkHistory';
import Coffee from './Coffee';
import Contact from './Contact';
import Navigation from './Navigation';
const Home: React.FC = () => {
    return (
        <div>
            <Navigation />
            <Hero />
            <About />
            <Work />
            <WorkHistory />
            <Coffee />
            <Contact />
        </div>
    );
};

export default Home; 
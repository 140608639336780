import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface CoffeePhoto {
    src: string;
    date: string;
    place: string;
    coffee: string;
}

const coffeePhotos: CoffeePhoto[] = [
    {
        src: '/coffee-photos/1.jpeg',
        date: 'Saturday, October 5, 2024',
        place: 'Good Morning!',
        coffee: 'Benchmark Blend from Pallet Coffee Roasters'
    },
    {
        src: '/coffee-photos/2.jpg',
        date: 'Monday, September 2, 2024',
        place: 'Coffee from Abbotsford',
        coffee: 'Espresso #2 from Aurora Roasters'
    },
    {
        src: '/coffee-photos/3.jpg',
        date: 'Thursday, August 22, 2024',
        place: 'Afternoon Coffee',
        coffee: 'Marine Roast from Been around the world'
    },
    {
        src: '/coffee-photos/4.jpeg',
        date: 'Thursday, June 9, 2022',
        place: 'My first ever espresso cup',
        coffee: 'Unknown from Blacksmith Roasters, Abu Dhabi'
    },
    {
        src: '/coffee-photos/5.jpg',
        date: 'Monday, October 16, 2023',
        place: 'Birthday Coffee at Matchstick',
        coffee: 'Bulldog from Matchstick'
    },
    {
        src: '/coffee-photos/6.jpg',
        date: 'Saturday, October 26, 2024',
        place: 'Cold Mocha day',
        coffee: 'Abuelita + Colombia from Matchstick'
    },
    {
        src: '/coffee-photos/7.jpeg',
        date: 'Friday, August 9, 2024',
        place: 'Affogato',
        coffee: 'Bulldog from Matchstick'
    },
    {
        src: '/coffee-photos/8.jpeg',
        date: 'Saturday, April 6, 2024',
        place: 'First decent latte art',
        coffee: 'Catalogue from Matchstick'
    },
    {
        src: '/coffee-photos/9.jpeg',
        date: 'Saturday, August 12, 2023',
        place: 'in Lahore',
        coffee: 'Unknown from Artisan Cafe, Lahore'
    },
    {
        src: '/coffee-photos/10.jpeg',
        date: 'Wednesday, June 19, 2024',
        place: 'Montreal Summer',
        coffee: 'Unknown from Crew Collective Cafe , Montreal'
    }
];

const MarqueeContent = () => (
    <span className="mx-6">
        ☕ Brewing happiness one cup at a time &nbsp;&nbsp;•&nbsp;&nbsp;
        Life happens after coffee &nbsp;&nbsp;•&nbsp;&nbsp;
        Over 1000 cups brewed &nbsp;&nbsp;•&nbsp;&nbsp;
        42 different beans tried &nbsp;&nbsp;•&nbsp;&nbsp;
        Making pour life decisions &nbsp;&nbsp;•&nbsp;&nbsp;
        Bean there, done that &nbsp;&nbsp;•&nbsp;&nbsp;
    </span>
);

export default function CoffeeGallery() {
    const [selectedPhoto, setSelectedPhoto] = useState<CoffeePhoto | null>(null);

    // Split photos into three columns and duplicate them for seamless scroll
    const column1 = [...coffeePhotos, ...coffeePhotos].filter((_, i) => i % 3 === 0);
    const column2 = [...coffeePhotos, ...coffeePhotos].filter((_, i) => i % 3 === 1);
    const column3 = [...coffeePhotos, ...coffeePhotos].filter((_, i) => i % 3 === 2);

    return (
        <>
            <div className="grid grid-cols-3 gap-3 h-full">
                {/* Column 1 - Moving Down Fast */}
                <motion.div
                    className="flex flex-col gap-3"
                    animate={{
                        y: [0, -50 * column1.length],
                    }}
                    transition={{
                        repeat: Infinity,
                        duration: 15,
                        ease: "linear",
                    }}
                >
                    {column1.map((photo, index) => (
                        <div
                            key={index}
                            className="relative aspect-square rounded-lg overflow-hidden cursor-pointer transform transition-transform hover:scale-105"
                            onClick={() => setSelectedPhoto(photo)}
                        >
                            <img
                                src={photo.src}
                                alt={`Coffee moment at ${photo.place}`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    ))}
                </motion.div>

                {/* Column 2 - Moving Up Medium */}
                <motion.div
                    className="flex flex-col gap-3"
                    animate={{
                        y: [-50 * column2.length, 0],
                    }}
                    transition={{
                        repeat: Infinity,
                        duration: 20,
                        ease: "linear",
                    }}
                >
                    {column2.map((photo, index) => (
                        <div
                            key={index}
                            className="relative aspect-square rounded-lg overflow-hidden cursor-pointer transform transition-transform hover:scale-105"
                            onClick={() => setSelectedPhoto(photo)}
                        >
                            <img
                                src={photo.src}
                                alt={`Coffee moment at ${photo.place}`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    ))}
                </motion.div>

                {/* Column 3 - Moving Down Slow */}
                <motion.div
                    className="flex flex-col gap-3"
                    animate={{
                        y: [0, -50 * column3.length],
                    }}
                    transition={{
                        repeat: Infinity,
                        duration: 25,
                        ease: "linear",
                    }}
                >
                    {column3.map((photo, index) => (
                        <div
                            key={index}
                            className="relative aspect-square rounded-lg overflow-hidden cursor-pointer transform transition-transform hover:scale-105"
                            onClick={() => setSelectedPhoto(photo)}
                        >
                            <img
                                src={photo.src}
                                alt={`Coffee moment at ${photo.place}`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    ))}
                </motion.div>
            </div>

            {/* Modal */}
            <AnimatePresence>
                {selectedPhoto && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
                        onClick={() => setSelectedPhoto(null)}
                    >
                        <motion.div
                            initial={{ scale: 0.95 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.95 }}
                            className="bg-white rounded-lg overflow-hidden max-w-2xl w-full"
                            onClick={e => e.stopPropagation()}
                        >
                            <img
                                src={selectedPhoto.src}
                                alt={`Coffee moment at ${selectedPhoto.place}`}
                                className="w-full aspect-square object-cover"
                            />
                            <div className="p-4">
                                <p className="text-sm text-gray-500">{new Date(selectedPhoto.date).toLocaleDateString()}</p>
                                <p className="font-semibold mt-1">{selectedPhoto.place}</p>
                                <p className="text-brown-600">{selectedPhoto.coffee}</p>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
} 
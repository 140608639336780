import React, { Fragment, useState, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';

interface TagSelectProps {
    allTags: string[];
    selectedTags: string[];
    onChange: (tags: string[]) => void;
}

const TagSelect: React.FC<TagSelectProps> = ({
    allTags,
    selectedTags,
    onChange
}) => {
    const [tagSearch, setTagSearch] = useState('');

    const filteredTags = useMemo(() => {
        return allTags.filter(tag =>
            tag.toLowerCase().includes(tagSearch.toLowerCase())
        );
    }, [allTags, tagSearch]);

    return (
        <Listbox
            value={selectedTags}
            onChange={onChange}
            multiple
        >
            <div className="relative">
                <Listbox.Button className="relative w-full bg-gray-100 border border-gray-200 rounded-lg py-2 pl-3 pr-10 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-brown-500 focus:border-brown-500 transition-colors hover:bg-gray-50">
                    <span className="block truncate text-sm">
                        {selectedTags.length === 0
                            ? 'Select tags...'
                            : `${selectedTags.length} tag${selectedTags.length === 1 ? '' : 's'} selected`
                        }
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-lg py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        <div className="sticky top-0 bg-white px-3 py-2">
                            <input
                                type="text"
                                className="w-full bg-gray-50 border border-gray-200 rounded-md text-sm px-3 py-2 focus:outline-none focus:ring-1 focus:ring-brown-500 focus:border-brown-500"
                                placeholder="Search tags..."
                                value={tagSearch}
                                onChange={(e) => setTagSearch(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                        {filteredTags.map((tag) => (
                            <Listbox.Option
                                key={tag}
                                value={tag}
                                className={({ active }) =>
                                    `${active ? 'bg-brown-50 text-brown-900' : 'text-gray-900'}
                                    cursor-default select-none relative py-2 pl-10 pr-4 hover:bg-brown-50 transition-colors`
                                }
                            >
                                {({ selected }) => (
                                    <>
                                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                            {tag}
                                        </span>
                                        {selected && (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brown-600">
                                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                                </svg>
                                            </span>
                                        )}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
};

export default TagSelect; 
import React from 'react';
import { motion } from 'framer-motion';
import { personalInfo } from '../data';
import GeometricBackground from './hero/GeometricBackground';

const Hero: React.FC = () => {
    return (
        <section className="min-h-screen flex items-center justify-center relative overflow-hidden">
            {/* Background */}
            <GeometricBackground />

            {/* Content */}
            <div className="container mx-auto px-4 py-20 relative z-10">
                <motion.div
                    className="max-w-6xl mx-auto bg-white rounded-3xl p-12 shadow-lg"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                >
                    <div className="flex flex-col md:flex-row items-center gap-16">
                        {/* Profile Image */}
                        <motion.div
                            className="relative"
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <div className="w-48 h-48 md:w-56 md:h-56 relative z-10">
                                <motion.img
                                    src="https://avatars.githubusercontent.com/u/13153442?v=4"
                                    alt="Profile"
                                    className="w-full h-full rounded-3xl object-cover shadow-xl"
                                    whileHover={{ scale: 1.05 }}
                                    transition={{ type: "spring", stiffness: 300 }}
                                />
                            </div>
                        </motion.div>

                        {/* Content */}
                        <div className="flex-1 text-center md:text-left">
                            <motion.h1
                                className="text-3xl md:text-5xl font-bold mb-6 text-brown-800"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.8, delay: 0.2 }}
                            >
                                {personalInfo.title}
                            </motion.h1>
                            <motion.p
                                className="text-xl md:text-2xl text-brown-800 mb-10 leading-relaxed max-w-3xl"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.8, delay: 0.4 }}
                            >
                                {personalInfo.subtitle}
                            </motion.p>
                            <motion.div
                                className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8, delay: 0.6 }}
                            >
                                <motion.a
                                    href="#projects"
                                    className="group bg-brown-700 text-white px-8 py-3 rounded-xl font-medium
                                        relative overflow-hidden shadow-lg"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <span className="relative z-10">View My Work</span>
                                    <motion.div
                                        className="absolute inset-0 bg-brown-800"
                                        initial={{ x: "-100%" }}
                                        whileHover={{ x: 0 }}
                                        transition={{ type: "spring", stiffness: 100 }}
                                    />
                                </motion.a>
                                <motion.a
                                    href="#contact"
                                    className="group border-2 border-brown-700 text-brown-700 px-8 py-3 rounded-xl font-medium
                                        relative overflow-hidden shadow-lg"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <span className="relative z-10 transition-colors duration-200">
                                        Let's Connect
                                    </span>
                                    <motion.div
                                        className="absolute inset-0 bg-brown-700"
                                        initial={{ x: "-100%" }}
                                        whileHover={{ x: 0 }}
                                        transition={{ type: "spring", stiffness: 100 }}
                                    />
                                </motion.a>
                            </motion.div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default Hero; 
import React, { useState } from 'react';
import { skills, personalInfo } from '../data';
import '../styles/about.css';

const About: React.FC = () => {
    const [expandedSkill, setExpandedSkill] = useState<string | null>(null);
    const [activeHighlight, setActiveHighlight] = useState<string | null>(null);

    const toggleSkill = (skillName: string) => {
        setExpandedSkill(expandedSkill === skillName ? null : skillName);
    };

    const highlightText = (text: string) => {
        let processedText = text;
        personalInfo.highlightedPhrases.forEach((phrase) => {
            const escapedPhrase = phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            processedText = processedText.replace(
                new RegExp(`(${escapedPhrase})`, 'gi'),
                `<span class="highlight ${activeHighlight === phrase ? 'active' : ''}" 
                       onclick="this.classList.toggle('active')"
                       onmouseleave="this.classList.remove('active')">$1</span>`
            );
        });

        return (
            <span
                dangerouslySetInnerHTML={{ __html: processedText }}
                className="leading-relaxed"
            />
        );
    };

    return (
        <section id="about" className="py-16 bg-white scroll-mt-20">
            <div className="container mx-auto px-4 sm:px-6">
                <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">About Me</h2>
                <div className="grid md:grid-cols-3 gap-8 sm:gap-12">
                    <div className="md:col-span-2">
                        <p className="text-gray-700 text-base sm:text-lg md:text-xl" style={{ lineHeight: '2.5' }}>
                            {highlightText(personalInfo.about)}
                        </p>
                    </div>
                    <div className="md:col-span-1">
                        <h3 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6">Technical Skills</h3>
                        <div className="space-y-3 sm:space-y-4">
                            {skills.map((skill) => (
                                <div key={skill.name} className="group">
                                    <button
                                        onClick={() => toggleSkill(skill.name)}
                                        className="w-full text-left focus:outline-none"
                                    >
                                        <div className="flex justify-between items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                                            <span className="text-gray-800 font-medium group-hover:text-brown-600 transition-colors">
                                                {skill.name}
                                            </span>
                                            <svg
                                                className={`w-4 h-4 text-gray-400 transform transition-transform duration-300 
                                                    ${expandedSkill === skill.name ? 'rotate-180' : ''}`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={1.5}
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                        </div>
                                    </button>

                                    <div
                                        className={`grid transition-all duration-300 ease-in-out ${expandedSkill === skill.name
                                            ? 'grid-rows-[1fr] opacity-100 mt-3'
                                            : 'grid-rows-[0fr] opacity-0'
                                            }`}
                                    >
                                        <div className="overflow-hidden">
                                            <div className="flex flex-wrap gap-2 p-4">
                                                {skill.technologies.map((tech) => (
                                                    <span
                                                        key={tech}
                                                        className="px-2.5 py-0.5 bg-white text-gray-600 rounded-full text-sm
                                                            border border-gray-200 hover:bg-brown-50 hover:text-brown-700 
                                                            hover:border-brown-200 transition-colors"
                                                    >
                                                        {tech}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About; 